<template>
    <!-- Login Modal -->
        <div class="row">
            <div class="col-lg-6">
                <div class="registration">
                    <div class="card">
                        <div class="card-body">
                            <span class="shape"></span>
                            <div class="row" v-if="errMsgFlagA">
								<div class="col-lg-12">
									<div class="alert alert-danger h5 bg-danger text-light p-2 alert-dismissible fade show" role="alert">{{errMsg}}</div>
								</div>
							</div>
							<div class="row" v-if="msgFlagA">
								<div class="col-lg-12">
										<div class="alert alert-success h5 bg-success text-light p-2  alert-dismissible fade show" role="alert">{{msg}}</div>
								</div>
							</div>
                       
                            <form  class="row p-5" @submit.prevent>
                                <div class="col-lg-12 form-group text-center">
                                    <h3>Sigin In</h3>
                                </div>
                               	<div class="form-group col-lg-12">
									<label for="">Phone  <span class="required-star"></span></label>
									<input type="text" class="form-control shadow-none" placeholder="Enter Your Phone" v-model="userName" >
								</div>
								<div class="form-group col-lg-12">
									<label for="">Password <span class="required-star"></span></label>
									<input type="password" class="form-control shadow-none" placeholder="Enter Password" v-model="password">
								</div>
								<div class="form-group col-lg-12">
									<div class="row no-gutters">
										<div class="col-lg-6">
											<!-- <input type="checkbox" name="checkbox" id="remember">
											<label for="remember">Remember me</label> -->
										</div>
										<div class="col-lg-6 text-right">
											<router-link :to="{name:'customer.password.reset'}">Forget Password</router-link>
										</div>
									</div>

								</div>
								<div class="form-group">
									<button type="button" class="btn btn-success rounded-pill" v-on:click="signinFunction()">Sign In</button>
								</div>
								<!-- <SocialLogin/> -->
                            </form>       
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">

                <div class="registration">
                    <div class="card">
                        <div class="card-body">
                            
                            <span class="shape"></span>
                    		<div class="row" v-if="errMsgFlagB">
								<div class="col-lg-12">
									<div class="alert alert-danger h5 bg-danger text-light p-2 alert-dismissible fade show" role="alert">{{errMsg}}</div>
								</div>
							</div>
							<div class="row" v-if="msgFlagB">
								<div class="col-lg-12">
										<div class="alert alert-success h5 bg-success text-light p-2  alert-dismissible fade show" role="alert">{{msg}}</div>
								</div>
							</div>
							<form  class="row p-5" @submit.prevent>
								<div class="col-lg-12 form-group text-center">
                                    <h3>Registration</h3>
                                </div>
                                <slot v-if="signUpForm">
									<div class="form-group">
										<label for="">Name:<span class="required-star"></span></label>
										<input type="text" class="form-control shadow-none" placeholder="Enter Your Name" v-model="name" >
									</div>
									<!-- <div class="form-group">
										<label for="">Email: <span class="required-star"></span></label>
										<input type="text" class="form-control shadow-none" placeholder="Enter Email Address" v-model="email" >
									</div> -->
									<div class="form-group">
										<label for="">Phone:<span class="required-star"></span></label>
										<input type="text" class="form-control shadow-none" placeholder="Enter Your Phone No" v-model="phone" >
									</div>
									<div class="form-group">
										<label for="">Password <span class="required-star"></span></label>
										<input type="password" class="form-control shadow-none" placeholder="Enter Password" v-model="newPassword">
									</div>
									<div class="form-group">
										<label for="">Confirm Password <span class="required-star"></span></label>
										<input type="password" class="form-control shadow-none" placeholder="Confirm Password" v-model="conPassword">
										<slot v-if="countDown>0">
											<span href="#" class="text-danger">Please Wait For {{ countDown }}`s To Get Your OTP.</span>
										</slot><br>
									</div>
									<div class="form-group">
									
										<button type="button" :disabled="countDown>0" class="btn btn-success rounded-pill" @click="verifyCustomerInfo()">Submit</button>
									</div>
								</slot>
								<slot v-if="otpForm">
										<div class="form-group">
											<label for="">Name:<span class="required-star"></span></label>
											<label class="form-control shadow-none">{{name}}</label>
										</div>
										<!-- <div class="form-group">
											<label for="">Email:<span class="required-star"></span></label>
											<label class="form-control shadow-none">{{email}}</label>
										</div> -->
										<div class="form-group">
											<label for="">Phone:<span class="required-star"></span></label>
											<label class="form-control shadow-none">{{phone}}</label>
										</div>
									
										<div class="form-group">
											<label for="">One Time Password <span class="required-star"></span></label>
											<input type="password" class="form-control shadow-none" placeholder="Enter One Time Password" v-model="otp">
										</div>
										<div class="form-group">
										<div class="row no-gutters">
											<div class="col-lg-6">
												<slot v-if="countDown>0">
													<a href="#" class="text-danger">Resend OTP  in {{ countDown }}s</a>
												</slot>
												<slot v-else>
													<a href="#" @click="verifyCustomerInfo()">Resend OTP </a>
												</slot>
											</div>
											<div class="col-lg-6 text-right">
												<a href="#" @click="signUpForm=true;otpForm=false;">Retype Info</a>
											</div>
										</div>

										</div>
										<div class="form-group">
											<button type="button" class="btn btn-success rounded-pill" @click="signUpFunction()">Sign Up</button>
										</div>
								</slot>
							</form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- /Login Modal -->
</template>
<script>

export default {
	
	data() {
	    return {
      customerPhone:this.$store.state.customerPhone,
      token:this.$store.state.apiToken,
      remember:this.$store.state.rememberToken,
			name:null,
			phone:null,
			email:null,
			newPassword:null,
			conPassword:null,
			msgFlagA:false,
			errMsgFlagA:false,
			msgFlagB:false,
			errMsgFlagB:false,
			msg:null,
			errMsg:null,
			signUpForm:true,
			otpForm:false,
			countDown:0,
			otp:null,
			userName:null,
			password:null,
		}
	},
	 beforeMount(){
       this.$store.commit("minimizeCartView");
      this.getCustomerInfo();
    },
    methods:{
      getCustomerInfo(){
        if(this.$store.state.customerInfo!=null){
            let customerInfo=this.$store.state.customerInfo;
            let formData=new FormData();
            formData.append("phone",this.customerPhone);
            formData.append("token",this.token);
            formData.append("remember",this.remember);

            this.$http.post("auth/check",formData)
            .then(response=>{
              if(response.data.msgFlag==true){
                 // this.$store.commit("storeCustomerInfo",response.data.customerInfo);
                  this.$router.push({name:'PreOrderCheckout'});
                
              }
              else{
                // this.$store.commit("storeCustomerInfo",null);
                // this.$router.push({name:'signinfor.checkout'});
              }
            })
            .catch(error=>{console.dir(error)})
            .finally();
        }
      },
    signinFunction(){
    		if(this.formValidation()){
    			let formData=new FormData();
	    		formData.append("userName",this.userName);
	    		formData.append("password",this.password);
	    		this.$http.post("customer/signin",formData)
	    		 .then(response=>{
	    		 	if(response.data.msgFlag==true){
	    		 		this.$toaster.success(response.data.msg);
	    		 		this.$store.commit('storeCustomerInfo',response.data.customerInfo);
	    		 		this.userName=null;
	    		 		this.password=null;
	    		 		this.$router.push({name:'PreOrderCheckout'});
	    		 	}
	    		 	else{
	    		 		this.errMsgFlagA=response.data.errMsgFlag;
	    		 		this.errMsg=response.data.errMsg;
	    		 		this.$toaster.warning(this.errMsgA);
	    		 	}
	    		 })
	    		 .catch(error=>{console.dir(error)})
	    		 .finally();
    		}
    	},
    	formSubmitPrevent(e){
    		 e.preventDefault();
    	},
    	formValidation(){
    		if(this.userName!=null && this.password!=null){
    			return true;
    		}
    		else{
    			if(this.userName==null){
    				this.errMsg="Please Enter Your Phone  First.";
    				this.$toaster.warning(this.errMsg);
    			}
    			if(this.password==null){
    				this.errMsg="Please Enter Password.";
    				this.$toaster.warning(this.errMsg);
    			}
    			this.errMsgFlagA=true;
    			return false;
    		}
    	},
    	resetRegistrationForm(){
    		this.name=null;
    		this.phone=null;
    		this.email=null;
    		this.password=null;
    		this.conPassword=null;
    		this.otp=null;
    		this.otpForm=false;
    		this.signUpForm=true;
    	},
    	countDownTimer() {
                if(this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.countDownTimer()
                    }, 1000)
                }
         },
    	emailValidation() 
		{
			let pattern=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			let flag=pattern.test(this.email);
		 	if(flag)
		 		return true;
		 	else
		 		return false;
		},
       signUpFormValidation(){
       	if(this.name!=null  && this.phone!=null && this.newPassword!=null && this.conPassword!=null){
       		if(this.phoneNumberValidation() ){
       			if(this.newPassword==this.conPassword){
       				return true;
       			}
       			else{
       				this.errMsg="Please Confirm Password.";
       				this.errMsgFlagB=true;
       				this.$toaster.warning(this.errMsg);
       				
       				return false;
       			}
       		}
       		else{
       			if(!this.phoneNumberValidation()){
       				this.errMsg="Please Enter A Valid Bangladeshi Phone Number.";
       				this.$toaster.warning(this.errMsg);
       			}
       			// if(!this.emailValidation()){
       			// 	this.errMsg="Please Enter An Valid Email Address.";
       			// 	this.$toaster.warning(this.errMsg);
       			// }
       			this.errMsgFlagB=true;
       			return false;
       		}
       	}
       	else{
       		if(this.name==null){
       			this.errMsg="Please Enter Your Name.";
       			this.$toaster.warning(this.errMsg);
       		}
       		// if(this.email==null){
       		// 	this.errMsg="Please Enter Your Mail Address.";
       		// 	this.$toaster.warning(this.errMsg);
       		// }
       		if(this.newPassword==null){
       			this.errMsg="Please Enter Password.";
       			this.$toaster.warning(this.errMsg);
       		}
       		if(this.conPassword==null){
       			this.errMsg="Please Confirm Password.";
       			this.$toaster.warning(this.conPassword);
       		}
       		this.errMsgFlagB=true;
       		return false;
       	}
   	  },
      verifyCustomerInfo(){
      	if(this.signUpFormValidation()){
      		let formData=new FormData();
      		formData.append("name",this.name);
      		formData.append("phone",this.phone);
      		// formData.append("email",this.email);
      		formData.append("password",this.newPassword);
      		formData.append("conPassword",this.conPassword);
      		formData.append("otp",this.otp);
			this.countDown=180;
			this.countDownTimer();
      		this.$http.post("verify/customer/info",formData)
      		 .then(response=>{
      		 	this.errMsgFlagB=response.data.errMsgFlag;
      		 	this.errMsg=response.data.errMsg;
      		 	this.msgFlagB=response.data.msgFlag;
      		 	this.msg=response.data.msg;
      		 	if(response.data.msgFlag==true){
      		 		this.$toaster.success(this.msg);
      		 		this.signUpForm=false;
      		 		this.otpForm=true;
      		 		
      		 	}
      		 	if(response.data.errMsgFlag==true){
      		 		this.$toaster.warning(this.errMsg);
      		 	}
      		 })
      		 .catch(error=>{console.dir(error)})
      		 .finally();
      	}
       },
       signUpFunction(){
      	if(this.signUpFormValidation()){
      		let formData=new FormData();
      		formData.append("name",this.name);
      		formData.append("phone",this.phone);
      		formData.append("email",this.email);
      		formData.append("password",this.newPassword);
      		formData.append("conPassword",this.conPassword);
      		formData.append("otp",this.otp);
      		this.$http.post("verify/customer/phone",formData)
      		 .then(response=>{
      		 	this.errMsgFlagB=response.data.errMsgFlag;
      		 	this.errMsg=response.data.errMsg;
      		 	this.msgFlagB=response.data.msgFlag;
      		 	this.msg=response.data.msg;
      		 	if(response.data.msgFlag==true){
      		 		this.$toaster.success(this.msg);
      		 		this.signUpForm=false;
      		 		this.otpForm=true;
      		 		if(response.data.loginFlag){
      		 			this.$store.commit('storeCustomerInfo',response.data.customerInfo);
      		 			this.$router.push({name:'PreOrderCheckout'});
      		 			this.resetRegistrationForm();
      		 		}
      		 		this.resetRegistrationForm();
      		 	}
      		 	if(response.data.errMsgFlag==true){
      		 		this.$toaster.warning(this.errMsg);
      		 	}
      		 })
      		 .catch(error=>{console.dir(error)})
      		 .finally();
      	}
       },
       phoneNumberValidation()
	   {
	      let pattern=/(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/;
	      let flag=pattern.test(this.phone);
	      if(flag)
	        return true;
	      else
	        return false;
	   },
    	
    }
}
</script>
